import React, { useState, useRef, useEffect } from 'react';
import './index.scss';

const Collapse = ({ children, isOpen }) => {
  const [childHeight, setChildHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    const childHeightRaw = contentRef.current.clientHeight;
    const childHeight = `${childHeightRaw / 16}rem`;
    setChildHeight(childHeight);
  }, [isOpen]);

  return (
    <div className="collapse" style={{ maxHeight: isOpen ? childHeight : 0 }}>
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

Collapse.defaultProps = {
  isOpen: false,
};

const App = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <>
      <button className='collapseBtn' onClick={toggle}>{props.toggleName}</button>
      <Collapse isOpen={isOpen}>
        <section className="sample-content">
          {props.content}
        </section>
      </Collapse>
    </>
  );
};

export default App;
