import { useEffect, useState } from 'react'
import chainlink_logo from '../../assets/images/chainlink_logo.png'
import ethereum_logo from '../../assets/images/ethereum_logo.png'
import metamask_logo from '../../assets/images/metamask_logo.png'
import moralis_logo from '../../assets/images/moralis_logo.png'
import solidity_logo from '../../assets/images/solidity_logo.png'
import uniswap_logo from '../../assets/images/uniswap_logo.png'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { NavLink } from 'react-router-dom'

const Blockchain = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['B', 'l', 'o', 'c', 'k', 'c', 'h', 'a', 'i', 'n']}
              idx={15}
            />
          </h1>
          <hr/>
          <p align="LEFT">
            <b><u>Blockchain Development</u></b>
            <br/>
            <br/>
            I have built several dApps on Ethereum, Solana and EOSIO. These applications are:
            <ul>
                <li>ETH Exchange with my own ERC-20 Token -{' '}
                    <a 
                        style={{color: '#ffd700'}}
                        href={'https://github.com/sethpow/ethereum-exchange'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub Repo
                    </a>
                </li>
                <li>Ethereum Video Platform - {' '}
                    <a
                        style={{color: '#ffd700'}}
                        href={'https://github.com/awakesoftware/alpha1'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub Repo
                    </a>
                </li>
                <li>IPFS Video Platform - {' '}
                    <a
                        style={{color: '#ffd700'}}
                        href={'https://github.com/sethpow/ethereum-video-platform'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub Repo
                    </a>
                </li>
                <li>Various Solidity Smart Contracts - {' '}
                    <a
                        style={{color: '#ffd700'}}
                        href={'https://github.com/sethpow/solidity-smart-contracts'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub Repo
                    </a>
                </li>
                <li>EOS.IO dApp - {' '}
                    <a
                        style={{color: '#ffd700'}}
                        href={'https://github.com/sethpow/eosio_doggo_dapp'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        GitHub Repo
                    </a>
                </li>
            </ul>
            <i>Blockchain Technologies:</i>
            <ul>
              <li>Solidity</li>
              <li>MetaMask</li>
              <li>Infura</li>
              <li>IPFS</li>
              <li>OpenZeppelin</li>
              <li>Hardhat (Truffle/Ganache pre 2023)</li>
            </ul>
          </p>
          <hr/>
          <p>
            Feel free to visit some of my past blockchain projects {' '}
            <NavLink
              to="/portfolio"
              className="flat-button"
              activeClassName="flat-button-active"
              style={{ color: '#ffd700' }}
            >
              here!
            </NavLink>
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
                <img style={{ width: '75%' }} src={chainlink_logo} alt="chainlink" />
            </div>
            <div className="face2">
                <img style={{ width: '75%', rotate: '270deg' }} src={ethereum_logo} alt="ethereum" />
            </div>
            <div className="face3">
                <img style={{ width: '75%' }} src={metamask_logo} alt="metamask" />
            </div>
            <div className="face4">
                <img style={{ width: '85%' }} src={moralis_logo} alt="moralis" />
            </div>
            <div className="face5">
                <img style={{ width: '150%' }} src={solidity_logo} alt="solidity" />
            </div>
            <div className="face6">
                <img style={{ width: '85%' }} src={uniswap_logo} alt="uniswap" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Blockchain
