import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import profile from '../../assets/images/me/profile.jpg';
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm a passionate and results-driven individual with a diverse background in the realms of software engineering, blockchain technology, and game development. Over the past three years, I have completely immersed myself into said fields, honing my skills and staying at the forefront of technological advancements.
          </p>
          <p align="LEFT">
            My approach to problem-solving is rooted in a balance between creativity and pragmatism. I believe in the power of collaboration and continuous learning, and I'm always eager to explore new technologies to stay ahead of the curve. As someone who is deeply passionate about technology, I am excited about the endless possibilities that arise.
          </p>
          <p>
            Let's connect and explore how my unique skillset and experiences can contribute to the success of your projects or initiatives.
          </p>
        </div>
        <div>
          <img
            alt="me"
            className="me-image"
            src={profile}
          />
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
