import { useEffect, useState } from 'react'
import blender_logo from '../../assets/images/blender_logo.png.png'
import cpp_logo from '../../assets/images/cpp_logo.png.png'
import perforce_logo from '../../assets/images/perforce_logo.png.png'
import python_logo from '../../assets/images/python_logo.png.png'
import teamcity_logo from '../../assets/images/teamcity_logo.png.png'
import ue5_logo from '../../assets/images/ue5_logo.png.png'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'

const GameDev = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['G', 'a', 'm', 'e', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'm', 'e', 'n', 't']}
              idx={15}
            />
          </h1>
          <p align="LEFT">
            When it comes to game development, C++ is my language of choice. With a solid foundation in this versatile and powerful language, I've successfully developed robust and scalable solutions, optimizing performance and ensuring the seamless execution of complex functionalities.
          </p>
          <p align="LEFT">
            Venturing into the expansive world of Unreal Engine 5, I've delved into the creation of next-level gaming experiences. Leveraging the powerful capabilities of this engine, I've contributed to the development of visually stunning and technologically advanced projects. My skills extend to both blueprint scripting and C++ programming within the Unreal Engine environment, allowing me to bring creative visions to life with precision and efficiency.
          </p>
          <p align="LEFT">
            Beyond coding, my proficiency in Blender adds a dimension to my skill set. As a 3D artist, I've utilized Blender to sculpt and shape digital worlds, creating assets that breathe life into virtual environments. From modeling to texturing and animation, my expertise in Blender complements my technical skills, enabling me to contribute holistically to the game development process.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <img style={{ width: '75%' }} src={blender_logo} alt="blender_logo" />
            </div>
            <div className="face2">
              <img style={{ width: '100%', rotate: '90deg' }} src={perforce_logo} alt="perforce_logo" />
            </div>
            <div className="face3">
              <img style={{ width: '75%' }} src={cpp_logo} alt="cpp_logo" />
            </div>
            <div className="face4">
              <img style={{ width: '75%' }} src={python_logo} alt="python_logo" />
            </div>
            <div className="face5">
              <img style={{ width: '75%' }} src={teamcity_logo} alt="teamcity_logo" />
            </div>
            <div className="face6">
              <img style={{ width: '75%' }} src={ue5_logo} alt="ue5_logo" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default GameDev
