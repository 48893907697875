import { useEffect, useState } from 'react'
import python_logo from '../../assets/images/python_logo.png.png'
import docker_logo from '../../assets/images/docker_logo.png'
import aws_logo from '../../assets/images/aws_logo.png'
import mern_logo from '../../assets/images/mern_logo.png'
import jenkins_logo from '../../assets/images/jenkins_logo.png'
import psql_logo from '../../assets/images/psql_logo.png'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import './index.scss'
import { NavLink } from 'react-router-dom'

const WebDev = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    return setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['W', 'e', 'b', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'm', 'e', 'n', 't']}
              idx={15}
            />
          </h1>
          <p>
            <b><u>Frontend Development</u></b>
            <br/>
            I created this site in React!
            <br/>
            My frontend experience has been focused primarily on React, working with state (some Redux experience), hooks & custom hooks, React Router, & Axios. Along with that come: HTML, CSS, and JavaScript.
          </p>
          <hr/>
          <p align="LEFT">
            <b><u>Backend Development</u></b>
            <br/>
            <br/>
            In both Python & Node, I have developed RESTful API’s & microservices, worked with asynchronous code, added authentication & authorization to the app, while using Flask & Express respectively as the base framework.
            <br/>
            <br/>
            <i>Other Key Backend Technologies:</i>
            <ul>
              <li>MongoDB</li>
              <li>Flask</li>
              <li>Express</li>
              <li>Jest</li>
              <li>PyTest</li>
            </ul>
          </p>
          <hr/>
          <p>
            <b><u>Other Key Technologies</u></b>
            <br/>
            <ul>
              <li>Git & GitHub</li>
              <li>Postman</li>
              <li>Vercel</li>
            </ul>
            Feel free to visit some of my past projects
            <NavLink
              to="/portfolio"
              className="flat-button"
              activeClassName="flat-button-active"
              style={{ color: '#ffd700', textDecoration: 'none' }}
            >
              {' '}here!
            </NavLink>
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
							<img style={{ width: '75%' }} src={docker_logo} alt="docker_logo" />
            </div>
            <div className="face2">
							<img style={{ width: '75%', rotate: '270deg' }} src={python_logo} alt="python_logo" />
            </div>
            <div className="face3">
							<img style={{ width: '75%' }} src={aws_logo} alt="aws_logo" />
            </div>
            <div className="face4">
							<img style={{ width: '75%' }} src={psql_logo} alt="psql_logo" />
            </div>
            <div className="face5">
							<img style={{ width: '100%' }} src={mern_logo} alt="mern_logo" />
            </div>
            <div className="face6">
							<img style={{ width: '65%' }} src={jenkins_logo} alt="jenkins_logo" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default WebDev
