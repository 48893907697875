import React, { useEffect, useState } from "react";
import Loader from "react-loaders";
import AnimatedLetters from "../AnimatedLetters";
import "./index.scss";
import Collapse from "../Collapse/Collapse";
import GDCollapse from "../Collapse/GDCollapse";
import Modal from "../Modal";
import {webDev, gameDev, blockchainDev} from "./i8n"

const Portfolio = () => { 
    const [letterClass, setLetterClass] = useState('text-animate');
    const [activeIndex, setActiveIndex] = useState(null);

    const handleCollapsibleClick = (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);

        return () => {
            clearTimeout(timer);
        }
    });

    return (
        <>
            <div className="container portfolio-page">
                <h1 className="page-title">
                    <AnimatedLetters
                        letterClass={letterClass}
                        strArray={"Portfolio".split("")}
                        idx={15}
                    />
                </h1>
                <GDCollapse
                    toggleName="Game Development"
                    content= {
                        gameDev.map((project) => (
                            <Modal
                                maxWidth="800px"
                                open_button={project.btn_txt}
                                title={project.title}
                                body={
                                    <iframe
                                        title={project.title}
                                        src={project.src}
                                        width={project.width}
                                        height={project.height}
                                        scrolling={project.scrolling}
                                    />
                                }
                                info={
                                    <>
                                        <p style={{color: "black"}}>
                                            {project.desc}
                                        </p>
                                        <p>
                                            <a href={project.info.link1} target="_blank" rel="noreferrer">{project.info.link1}</a>
                                        </p>
                                        <p>
                                            <a href={project.info.repo} target="_blank" rel="noreferrer">{project.info.repo}</a>
                                        </p>
                                    </>
                                }
                            />
                        ))
                    }
                />
                <Collapse
                    toggleName="Web Development"
                    content= {
                        webDev.map((project) => (
                            <Modal
                                maxWidth="800px"
                                open_button={project.btn_txt}
                                title={project.title}
                                body={
                                    <img
                                        title={project.title}
                                        src={project.src}
                                        width={project.width}
                                        height={project.height}
                                        scrolling={project.scrolling}
                                    />
                                }
                                info={
                                    <>
                                        <p style={{color: "black"}}>
                                            {project.desc}
                                        </p>
                                        <p>
                                            <a href={project.info.link1} target="_blank" rel="noreferrer">{project.info.link1}</a>
                                        </p>
                                        <p>
                                            <a href={project.info.repo} target="_blank" rel="noreferrer">{project.info.repo}</a>
                                        </p>
                                    </>
                                }
                            />
                        ))
                    }
                />
                <Collapse
                    toggleName="Blockchain Development"
                    content= {
                        blockchainDev.map((project) => (
                            <Modal
                                maxWidth="800px"
                                open_button={project.btn_txt}
                                title={project.title}
                                body={
                                    <iframe
                                        title={project.title}
                                        src={project.src}
                                        width={project.width}
                                        height={project.height}
                                        scrolling={project.scrolling}
                                    />
                                }
                                info={
                                    <>
                                        <p style={{color: "black"}}>
                                            {project.desc}
                                        </p>
                                        <p>
                                            <a href={project.info.link1} target="_blank" rel="noreferrer">{project.info.link1}</a>
                                        </p>
                                        <p>
                                            <a href={project.info.repo} target="_blank" rel="noreferrer">{project.info.repo}</a>
                                        </p>
                                    </>
                                }
                            />
                        ))
                    }
                />
            </div>
            <Loader type="pacman" />
        </>
    );
}

export default Portfolio;