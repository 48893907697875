import { Route, Routes } from 'react-router-dom'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Layout from './components/Layout'
import Portfolio from './components/Portfolio'
import WebDev from './components/WebDev'
import GameDev from './components/Game'
import Hobbies from './components/Hobbies'
import './App.scss'
import Blockchain from './components/Blockchain'

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/webdev" element={<WebDev />} />
          <Route path="/blockchaindev" element={<Blockchain />} />
          <Route path="/gamedev" element={<GameDev />} />
          <Route path="/hobbies" element={<Hobbies />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
