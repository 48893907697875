export const webDev =  [
    {
        "id": 1,
        "title": "MERN App",
        "desc": "",
        "btn_txt": "Video Hosting Platform",
        "src": "",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "",
            "link2": "",
            "repo": "https://github.com/awakesoftware/alpha1"
        }
    }
]

export const gameDev =  [
    {
        "id": 3,
        "title": "Legends of Myhria",
        "desc": "Working prototype of my Game Jam submission. Built in: C++ & Raylib.",
        "btn_txt": "Legends of Myhria - PTR",
        "src": "../portfolio/2/game_off_2023.html",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "",
            "link2": "",
            "repo": "https://github.com/sethpow/legends-of-myhria"
        }
    },
    {
        "id": 4,
        "title": "Unreal Engine 5 Platformer - Character Demo",
        "desc": "Character Demo; Built with Unreal Engine 5 Blueprints & PaperZD",
        "btn_txt": "2D Platformer Weapon Demo - UE5",
        "src": "../portfolio/4/megaPlatformer.mp4",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "",
            "link2": "",
            "repo": "https://github.com/sethpow/UnrealEngine5-MegaActionPlatformer"
        }
    },
    {
        "id": 5,
        "title": "Unreal Engine 5/Blueprints",
        "desc": "2D Platformer Game; Built with Unreal Engine 5 Blueprints.",
        "btn_txt": "2D Platformer - Unreal Engine 5",
        "src": "../portfolio/3/platformer.mp4",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "https://sethpow.itch.io/2d-platformer-unreal-engine-5-blueprints",
            "link2": "",
            "repo": ""
        }
    }
]

export const blockchainDev =  [
    {
        "id": 6,
        "title": "MERN App w/ ETH Smart Contract (Kovan) & Metamask",
        "desc": "Users can upload a video to IPFS, and the video name and description are stored on the ETH blockchain.",
        "btn_txt": "ETH Powered Video Hosting Platform",
        "src": "",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "",
            "link2": "",
            "repo": "https://github.com/awakesoftware/alpha1"
        }
    },
    {
        "id": 7,
        "title": "JavaScript Based Ethereum Exchange",
        "desc": "Exchange that allows users to trade ETH for my custom ERC20 token.",
        "btn_txt": "ETH Exchange App",
        "src": "",
        "width": "800px",
        "height": "675px",
        "scrolling": "no",
        "info": {
            "link1": "",
            "link2": "",
            "repo": "https://github.com/sethpow/ethereum-exchange"
        }
    }
]